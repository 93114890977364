// @see: https://www.gatsbyjs.com/docs/add-seo-component/
// @see: https://github.com/LekoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx
// @see: https://github.com/marisamorby/marisamorby.com/blob/master/packages/gatsby-theme-blog-sanity/src/components/seo.js
// @see: https://github.com/gatsbyjs/gatsby-starter-default/blob/master/src/components/seo.js



/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from 'react-helmet'
 import { useStaticQuery, graphql } from "gatsby"
 
 import { useIntl } from "gatsby-plugin-intl"
 import MenuUtils from "./s4n/Menu/utils"
 import { escapeRegExp } from 'lodash'; // @see: https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
 
 
 
 // Soft4Net START
 
 const isHomePage = (nodeId) => {
   if (Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId) {
     return true;
   }
 
   return false;
 }
 
 // Soft4Net STOP
 
 
 
 /**
  * Take data from Drupal and build metadata
  * @see: http://soft4net.test/pl/jsonapi/metatag_defaults/metatag_defaults
  * 
  * @param {*} props 
  */
 function SEO(props) {
 
   const { site, allMetatagDefaultsMetatagDefaults } = useStaticQuery(
     graphql`
       query {
 
         # Gatsby's data from from gatsby-config.js
         site {
           siteMetadata {
             title
             slogan
             description
             author
             siteUrl
           }
         }
 
         # Get drupal
         # Get drupal/metatag default configuration for each content types, like: Global, Front page, 403 access denied, 404 page not found, Taxonomy term, User, Node
         allMetatagDefaultsMetatagDefaults {
           edges {
             node {
               id
               drupal_internal__id
               status
               langcode
               label
               tags {
                 # basic
                   title
                   description
                   # abstract
                   # keywords
                 # advanced
                   canonical_url
                   # cache_control
               }
             }
           }
         }
 
       }
     `
   )
 
   const intl = useIntl();
   const t = intl.formatMessage;
 
 // console.log(props)
 
   let { 
     lang, title, description, meta, // https://github.com/gatsbyjs/gatsby-starter-default/blob/master/src/components/seo.js
     nodeId, abstract, keywords, field_metatag: drupal_entity_field_metatag, // Soft4Net
   } = props;
 
 
 
 
 
 
 // console.log(allMetatagDefaultsMetatagDefaults.edges)
 
   let drupal_metatag_default_config = null;
   
   // filter status and language
   drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.status && node.langcode === intl.locale);
 
   // filter for current page type
   drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.drupal_internal__id === `global`)[0].node;
   if (isHomePage(nodeId)) {
     drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.drupal_internal__id === `front`)[0].node;
   } else { // @todo: detect if it is node page type!!!
     drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.drupal_internal__id === `node`)[0].node;
   }
   // @todo: implement 403 and 404
   // drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.drupal_internal__id === `403`);
   // drupal_metatag_default_config = allMetatagDefaultsMetatagDefaults.edges.filter(({ node }) => node.drupal_internal__id === `404`);
 
   // console.log(drupal_metatag_default_config)
 
   const drupal_metatag_processed = drupal_entity_field_metatag || {
     // basic
       title: drupal_metatag_default_config.tags.title,
       description: drupal_metatag_default_config.tags.description,
       abstract: ``,
       keywords: ``,
     // advanced
       canonical_url: drupal_metatag_default_config.tags.canonical_url ? drupal_metatag_default_config.tags.canonical_url : ``,
       cache_control: ``,
   }
 
 
 
 
 
 
   // @see: https://stackoverflow.com/questions/15604140/replace-multiple-strings-with-multiple-other-strings START
   const mapObj = {
     // Escaped keys
     // [`\\[site:name\\]`]: t({ id: `system_site.name` }),
     // [`\\[site:slogan\\]`]: t({ id: `system_site.slogan` }),
     // [`\\[site:mail\\]`]: t({ id: `system_site.mail` }),
     // [`\\[node:title\\]`]: title, // property name becomes => \[node:title\]
     // [`\\[node:summary\\]`]: description || t({ id: `system_site.slogan` }),
     // [`\\[node:url\\]`]: ``,
     // [`\\[current-page:title\\]`]: title,
 
     // Not escaped keys, therefore we use: Object.keys(mapObj).map(key => key.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|')
     [`[site:name]`]: t({ id: `system_site.name` }),
     [`[site:slogan]`]: t({ id: `system_site.slogan` }),
     [`[site:mail]`]: t({ id: `system_site.mail` }),
     [`[node:title]`]: title, // property name becomes => \[node:title\]
     [`[node:summary]`]: description || t({ id: `system_site.slogan` }),
     [`[node:url]`]: ``,
     [`[current-page:title]`]: title,
   };
 // console.log(mapObj)
 
   const replaceAll = (str, mapObj) => {
     if (typeof str !== `string`) {
       return str;
     }
 
     // const mapObjKeys = Object.keys(mapObj).join("|"); // Unescaped keys
     const mapObjKeys = Object.keys(mapObj).map(key => escapeRegExp(key)); // Escaped keys
     const re = new RegExp(mapObjKeys.join('|'), "g");
     // const re = new RegExp(mapObjKeys.join('|'), "gi"); case insensitive
     // console.log(re)
 
     return str.replace(re, matched => {
         // console.log(matched) // [node:title], if escaped it matches the mapObj property key
         // return mapObj[matched.toLowerCase()] || matched; // gi
         return mapObj[matched] || matched; // g
     });
   }
   // @see: https://stackoverflow.com/questions/15604140/replace-multiple-strings-with-multiple-other-strings STOP
 
 
 
 
 
 
 // console.log(drupal_metatag_processed)
   // specifying an alternate destructured name to avoid conflict names with variable passed via props, like: title, description, keywords!!!
   let {
     // drupal/metatag basic
       title: drupalMetatagTitle,
       description: drupalMetatagDescription,
       abstract: drupalMetatagAbstract,
       keywords: drupalMetatagKeywords,
     // drupal/metatag advanced
       canonical_url,
       cache_control,
   } = drupal_metatag_processed;
 
 // console.log(`title: `, title)
 // console.log(`description: `, description)
 // console.log(drupalMetatagTitle)
 
   // title = title || drupalMetatagTitle
   // description = description || drupalMetatagDescription || t({ id: `system_site.slogan` })
   // abstract = abstract || drupalMetatagAbstract
   // keywords = keywords || drupalMetatagKeywords
 
 // console.log(`title: `, title)
 // console.log(`description: `, description)
 
 // process fields
   title = replaceAll(drupalMetatagTitle, mapObj); // we provide title in replaced string [node:title]
   description = replaceAll(drupalMetatagDescription, mapObj); // we provide description in replaced string [node:summary]
   abstract = abstract || replaceAll(drupalMetatagAbstract, mapObj);
   keywords = keywords || drupalMetatagKeywords;
   keywords = keywords && keywords.includes(`,`) ? 
     keywords.split(`,`).map(keyword => keyword.trim()) : keywords ? 
       [keywords.trim()] : [];
 
 // console.log(`[PROCESSED] title: `, title)
 // console.log(`[PROCESSED] description: `, description)
 // console.log(`[PROCESSED] keywords: `, keywords)
 
 
 
 
 
 
 // Soft4Net START
 
 // console.log(site)
 // console.log(site.siteMetadata);
 
   lang = intl.locale; // overwrite lang with value taken from gatsby-plugin-intl
 
   // Add extra meta attributes
   if (Array.isArray(keywords) && keywords.length > 0) {
     meta = meta.concat({
       name: `keywords`,
       content: keywords.join(`, `),
     });
   }
 
   // @see: https://www.helloimkyle.com/how-to-verify-a-gatsby-site-with-google-webmasters/
   if (`null` !== process.env.GOOGLE_SITE_VERIFICATION && process.env.GOOGLE_SITE_VERIFICATION.length > 0) {
     meta = meta.concat({
       name: 'google-site-verification', 
       content: process.env.GOOGLE_SITE_VERIFICATION,
     });
   }
   // meta = meta.concat({
   //   property: `og:url`,
   //   // content: url,
   // });
 
   const getLinkCanonical = (nodeId) => {
     const currentLanguage = intl.locale;
     const nodeMenuItemCurrentLocale = MenuUtils.getItemByNodeId(nodeId, currentLanguage);
 
     if (!nodeMenuItemCurrentLocale) {
       return null;
     }
 
     // for home page we want canonical like:
     if (isHomePage(nodeId)) {
       nodeMenuItemCurrentLocale.link.url = ``;
     }
 
     return `${site.siteMetadata.siteUrl}/${nodeMenuItemCurrentLocale.langcode}${nodeMenuItemCurrentLocale.link.url}`;
   }
 
   // @see: https://www.gatsbyjs.org/packages/gatsby-plugin-canonical-urls/
   const generateLinkCanonical = (nodeId) => {
     return (
       <Helmet
         defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
       >
         <link rel="canonical" href={getLinkCanonical(nodeId)} />
       </Helmet>
     )
   }
 
   const generateLinkAlternate = (nodeId) => {
     const defaultLanguage = process.env.SOFT4NET_LOCALE_DEFAULT || `en`;
     const aAvailableLanguages = process.env.SOFT4NET_AVAILABLE_LANGUAGES.split(','); // [ 'pl', 'en' ]
     const nodeMenuItemDefaultLocale = MenuUtils.getItemByNodeId(nodeId, defaultLanguage);
 
     if (!nodeMenuItemDefaultLocale) {
       return null;
     }
 
     // for home page we want canonical like: 
     if (isHomePage(nodeId)) {
       nodeMenuItemDefaultLocale.link.url = ``;
     }
 
     return (
       <Helmet
         defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
       >
         <link rel="alternate" hreflang="x-default" href={`${site.siteMetadata.siteUrl}/${nodeMenuItemDefaultLocale.langcode}${nodeMenuItemDefaultLocale.link.url}`} />
         {aAvailableLanguages.map(locale => {
           const nodeMenuItemInLocale = MenuUtils.getItemByNodeId(nodeId, locale);
 
           if (!nodeMenuItemInLocale) {
             return null;
           }
 
           // for home page we want canonical like: 
           if (isHomePage(nodeId)) {
             nodeMenuItemInLocale.link.url = ``;
           }
 
           return <link rel="alternate" hreflang={locale} href={`${site.siteMetadata.siteUrl}/${nodeMenuItemInLocale.langcode}${nodeMenuItemInLocale.link.url}`} />
         })}
       </Helmet>
     )
   }
 
 // Soft4Net STOP
 
 
 
 
 
 
   // Fallback to Gatsby's data from from gatsby-config.js if our vcalues missing after processing!!! START
   title = title || site.siteMetadata.title;
   description = description || site.siteMetadata.description;
 
 
 
 
 
 
   
   // CUSTOM START

   meta = [...meta];

   const hasMetaImageParam = meta.filter(metaParam => metaParam.property === `image`);
   if (!hasMetaImageParam?.length) {
     const SOCIAL_IMAGE_PATH = `/icons/icon-512x512.png`; // generated by plugin gatsby-plugin-manifest | or use `/static/custom/logo.svg`

     meta.push(
       {
         property: `image`,
         content: `${process.env.SOFT4NET_STATIC_BASE_URL}${SOCIAL_IMAGE_PATH}`,
       }, 
       {
         name: `image`,
         property: `og:image`,
         content: `${process.env.SOFT4NET_STATIC_BASE_URL}${SOCIAL_IMAGE_PATH}`,
       },
       {
         property: `twitter:image`,
         content: `${process.env.SOFT4NET_STATIC_BASE_URL}${SOCIAL_IMAGE_PATH}`,
       }
     );
   }

   meta.push(
     {
       property: `og:url`,
       content: getLinkCanonical(nodeId),
     }, 
   );

   meta.push(
     {
       name: `author`,
       content: `Soft4Net`,
     }, 
   );

   // CUSTOM STOP
 
 
 
 
 
 
   return (
     <React.Fragment>
       <Helmet
         defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
         htmlAttributes={{
           lang,
         }}
         title={title}
         titleTemplate={`%s`}
         meta={[
           {
             name: `description`,
             content: description,
           },
           {
             property: `og:title`,
             content: title,
           },
           {
             property: `og:description`,
             content: description,
           },
           {
             property: `og:type`,
             content: `website`,
           },
           {
             name: `twitter:card`,
             content: `summary`,
           },
           {
             name: `twitter:creator`,
             content: site.siteMetadata.author,
           },
           {
             name: `twitter:title`,
             content: title,
           },
           {
             name: `twitter:description`,
             content: description,
           },
         ]
         .concat(meta)
       }
       />
       {generateLinkCanonical(nodeId)}
       {generateLinkAlternate(nodeId)}
     </React.Fragment>
   )
 }
 
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   keywords: null,
   description: ``,
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   keywords: PropTypes.string,
   title: PropTypes.string.isRequired,
 }
 
 export default SEO